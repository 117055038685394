.hover14 figure {
    position: relative;
    overflow: hidden; /* Ensure the effect is clipped to the figure */
    border-radius: 10px 100px / 120px; /* Inherit border radius from the parent */
}

.hover14 figure::before {
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 2;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    transform: skewX(-30deg);
    border-radius: 10px 100px / 120px; /* Match the border radius */
}

.hover14 figure:hover::before {
    animation: shine 1.1s;
}

@keyframes shine {
    0% {
        left: -150%;
    }
    100% {
        left: 150%;
    }
}
